
import { defineComponent, reactive } from "vue";
import { mapGetters } from "vuex";
import { poemCli, poem } from "@/api";
export default defineComponent({
  name: "PoemList",
  data() {
    return {
      msg: "List",
      poems: reactive<Array<poem.Poem>>([]),
      title: "",
      type: "",
      typeList: [],
      page: 1,
      pageSize: 20,
      count: 0,
      loading: false,
      ending: false,
      time: null,
      rid: "",
    };
  },
  mounted: function () {
    this.loadPage();
  },
  computed: {
    ...mapGetters([
      "requestId",
      // ...
    ])
  },
  watch: {
    requestId: function () {
      this.poems = [];
      this.rid = this.$store.state.requestId
      if (this.$store.state.search.random) {
        this.loadRandom();
      } else {
        this.loadPage();
      }
    },
  },
  methods: {
    cli: () => poemCli(),
    loadMore: function () {
      this.page++;
      // console.log("loadMore");
      this.getList();
    },
    loadPage: function () {
      // window.onbeforeunload = e => { this.clearSearchInfo(); };
      this.init();
      this.getList();
    },
    loadRandom: function () {
      // window.onbeforeunload = e => { this.clearSearchInfo(); };
      this.init();
      this.loading = true;
      let req = new poem.RandomReq();
      req.setRid(this.rid)
      req.setSize(this.pageSize);

      // console.log("request list:");
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      this.cli().random(req, {}, function (err, res) {
        if (err) {
          // console.log(err);
        } else {
          // console.log("response:"+res);
          self.poems = self.poems.concat(res.getDataList());

          let pagination = res.getPagination();
          if (pagination) {
            self.count = pagination.getTotal();
            self.pageSize = pagination.getSize();
            self.loading = false;
            self.ending = pagination.getIsEnd();
            self.setPaginator(0, 0);
          }

          self.setNavTitle("");
        }
      });
    },
    setList: function (res: poem.ListRes) {
      let paginate = res.getPagination();
      if (paginate) {
        this.page = paginate.getPage();
        this.count = paginate.getTotal();
        this.pageSize = paginate.getSize();
        this.setPaginator(this.page, Math.round(this.count / this.pageSize));
        if (paginate.getIsEnd()) {
          this.ending = true;
        }
      }

      // 每一百页重置到第一行
      if (this.page % 100 == 1) {
        this.poems = [];
      }

      this.poems = this.poems.concat(res.getDataList());

      this.loading = false;


      // console.log(this.loading, this.ending);

      this.setNavTitle("");
    },
    getList: function () {
      this.loading = true;
      let req = new poem.ListReq();
      const search = this.$store.state.search
      req.setRid(this.rid)
      req.setSearch(search.search);
      req.setDynasty(search.dynasty);
      req.setGenre(search.genre);
      req.setIndex(search.index)
      req.setPage(this.page);
      req.setSize(this.pageSize);


      // console.log("request list:");
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      this.cli().list(req, {}, function (err, res) {
        // console.log("response:"+res, "\nerr:" + err);
        self.setList(res);
        if (err) {
          // console.log(err);
        }
      });
    },
    init() {
      if (this.rid == "") {
        this.rid = Date.now().toString();
      }
      this.page = 1;
      this.pageSize = 20;
      this.count = 0;
      this.loading = false;
      this.ending = false;
      // this.searchTitle = searchInfo.title || '';
      // this.searchGenre = searchInfo.genre || '';
      // this.searchDynasty = searchInfo.dynasty || '';
      // this.page = 1;
    },
    setNavTitle: function (val: string) {
      this.$emit("setNavTitle", [val]);
    },
    setPaginator: function (page: number, totalPage: number) {
      this.$emit("setPaginator", page, totalPage);
    },
  },
});
