
type tabType = {
  idx: string;
  icon: string;
};

import search from "./SearchTab.vue";
import setting from "./SettingTab.vue";
import attach from "./AttachTab.vue";
import about from "./AboutTab.vue";
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
export default defineComponent({
  name: "FilterTab",
  data() {
    return {
      tabClass: "",
      tabs: [
        { idx: "search", icon: "fe-search-circled" },
        { idx: "setting", icon: "fe-cog-circled" },
        { idx: "attach", icon: "fe-attach-circled" },
        { idx: "about", icon: "fe-info-circled" },
      ],
      // titles: [
      // ]
    };
  },
  components: {
    search,
    setting,
    attach,
    about,
  },
  created() {
    this.tabClass = "pure-u-1-" + this.tabs.length;
  },
  computed: {
    ...mapGetters([
      "activedFilterIdx",
      "activedFilterName",
    ]),
  },
  methods: {
    tabChange: function (tab: tabType) {
      this.$store.commit("enableFilter", tab.idx);
      // this.tabIdxName = tab.name;
      // this.tabIdxView = tab.show;
      // this.setNavTitle(this.titles);
    },
    setNavTitle: function (val: string) {
      this.$emit("setNavTitle", [val]);
    },
  },
});
