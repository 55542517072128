
import { defineComponent, reactive } from "vue";
import { poemCli, poem } from "@/api";
export default defineComponent({
  name: 'PoemDetail',
  data() {
    let p = new poem.Detail
    return {
      poem: reactive<poem.Detail>(p),
      showToolbar: false
    }
  },
  activated: function () {
    this.getDetail();
  },
  methods: {
    cli: () => poemCli(),
    getDetail: function () {
        let req = new poem.DetailReq();
        req.setUniqid(this.$route.params.id.toString())
        this.cli().detail(
            req, {}
        ).then((detail: poem.DetailRes) => {
            let p = detail.getDetail();
            if (p) {
                this.setPoem(p);
            }

        }).catch((err: Error) => {
            console.error(err);
        })
    },
    setPoem: function (p: &poem.Detail) {
      this.poem = p;
      this.setNavTitle(p.getTitle());
    },
    setNavTitle: function (val: string) {
      this.$emit('setNavTitle', [val]);
    }
  }
});
