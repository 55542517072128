
import { defineComponent } from "vue";
export default defineComponent({
  name: "SettingTab",
  data() {
    return {
    };
  },
  methods: {},
});
