import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { id: "filter" }
const _hoisted_2 = { class: "pure-g filter-nav" }
const _hoisted_3 = {
  class: "pure-u-1-2 filter-title",
  href: "#"
}
const _hoisted_4 = { class: "pure-u-1-2" }
const _hoisted_5 = { class: "pure-g" }
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = { class: "filter-tab-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.activedFilterName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.tabClass),
              key: index
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(["filter-nav menu-stretch-link", { actived: (_ctx.activedFilterIdx == tab.idx )}]),
                title: _ctx.activedFilterName,
                onClick: ($event: any) => (_ctx.tabChange(tab))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(tab.icon)
                }, null, 2)
              ], 10, _hoisted_6)
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activedFilterIdx)))
      ], 1024))
    ])
  ]))
}