// main.ts

import { PoemServiceClient } from "./pb/PoemServiceClientPb";
import poem from "./pb/poem_pb";

const TCP_ADDR = process.env.VUE_APP_BACKEND_ADDR;

export const poemCli = function () {
  return new PoemServiceClient(TCP_ADDR);
};

export {poem}

// export const poemListReq = function () {
//   return new msg.ListReq();
// };

// export const poemDetailReq = function () {
//   return new msg.DetailReq();
// };

// export type poemRes = msg.ListRes;
// export type poemRow = msg.Row;
// export type poemDetailRes = msg.DetailRes;
// export type poemDetail = msg.Detail;

// rq.setRid("123");
// rq.setSearch("abc");

// console.log("request:", rq.getSearch(), "\n");

// console.log("request list:");
// client.list(rq, function (err, res) {
//     if (err) {
//         console.log(err);
//     } else {
//         console.log("response:");
//         const list = res.getListList();
//         for (let index = 0; index < list.length; index++) {
//             const rows = list[index].getContentList();
//             for (let idx = 0; idx < rows.length; idx++) {
//                 console.log(rows[idx]);
//             }
//         }
//         // console.log(res.getListList())
//     }
// });
