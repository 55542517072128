import { DirectiveBinding } from 'vue'

export default {
  mounted(el: Element, binding: DirectiveBinding) {
    let target = el;
    const attrEl = el.querySelector(el.getAttribute('data-scrollTarget') || "scroll");
    if (attrEl instanceof Element) {
      target = attrEl;
    }

    target.addEventListener('scroll', e => {
      const disabled = JSON.parse(el.getAttribute("data-scrollDisabled") || "false");
      const elTarget = e.target;

      if (elTarget instanceof Element && !disabled && isBottom(elTarget)) {
        el.setAttribute('data-scrollDisabled', "true");
        binding.value(e);
      }
    });
  },
};

// scrollTop 小数问题
function isBottom(el: Element): boolean {
  // console.log(el.scrollHeight - el.scrollTop - el.clientHeight);
  return Math.abs(el.scrollHeight - el.scrollTop - el.clientHeight) < 1;
}