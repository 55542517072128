
import { defineComponent } from "vue";
export default defineComponent({
  name: "AttachTab",
  data() {
    return {
      msg: "Article Lists",
    };
  },
  methods: {},
});
