/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as poem_pb from './poem_pb';


export class PoemServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/pb.PoemService/List',
    grpcWeb.MethodType.UNARY,
    poem_pb.ListReq,
    poem_pb.ListRes,
    (request: poem_pb.ListReq) => {
      return request.serializeBinary();
    },
    poem_pb.ListRes.deserializeBinary
  );

  list(
    request: poem_pb.ListReq,
    metadata: grpcWeb.Metadata | null): Promise<poem_pb.ListRes>;

  list(
    request: poem_pb.ListReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poem_pb.ListRes) => void): grpcWeb.ClientReadableStream<poem_pb.ListRes>;

  list(
    request: poem_pb.ListReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poem_pb.ListRes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pb.PoemService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/pb.PoemService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorRandom = new grpcWeb.MethodDescriptor(
    '/pb.PoemService/Random',
    grpcWeb.MethodType.UNARY,
    poem_pb.RandomReq,
    poem_pb.RandomRes,
    (request: poem_pb.RandomReq) => {
      return request.serializeBinary();
    },
    poem_pb.RandomRes.deserializeBinary
  );

  random(
    request: poem_pb.RandomReq,
    metadata: grpcWeb.Metadata | null): Promise<poem_pb.RandomRes>;

  random(
    request: poem_pb.RandomReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poem_pb.RandomRes) => void): grpcWeb.ClientReadableStream<poem_pb.RandomRes>;

  random(
    request: poem_pb.RandomReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poem_pb.RandomRes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pb.PoemService/Random',
        request,
        metadata || {},
        this.methodDescriptorRandom,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/pb.PoemService/Random',
    request,
    metadata || {},
    this.methodDescriptorRandom);
  }

  methodDescriptorDetail = new grpcWeb.MethodDescriptor(
    '/pb.PoemService/Detail',
    grpcWeb.MethodType.UNARY,
    poem_pb.DetailReq,
    poem_pb.DetailRes,
    (request: poem_pb.DetailReq) => {
      return request.serializeBinary();
    },
    poem_pb.DetailRes.deserializeBinary
  );

  detail(
    request: poem_pb.DetailReq,
    metadata: grpcWeb.Metadata | null): Promise<poem_pb.DetailRes>;

  detail(
    request: poem_pb.DetailReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poem_pb.DetailRes) => void): grpcWeb.ClientReadableStream<poem_pb.DetailRes>;

  detail(
    request: poem_pb.DetailReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poem_pb.DetailRes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pb.PoemService/Detail',
        request,
        metadata || {},
        this.methodDescriptorDetail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/pb.PoemService/Detail',
    request,
    metadata || {},
    this.methodDescriptorDetail);
  }

  methodDescriptorRandSentence = new grpcWeb.MethodDescriptor(
    '/pb.PoemService/RandSentence',
    grpcWeb.MethodType.UNARY,
    poem_pb.RandSentenceReq,
    poem_pb.RandSentenceRes,
    (request: poem_pb.RandSentenceReq) => {
      return request.serializeBinary();
    },
    poem_pb.RandSentenceRes.deserializeBinary
  );

  randSentence(
    request: poem_pb.RandSentenceReq,
    metadata: grpcWeb.Metadata | null): Promise<poem_pb.RandSentenceRes>;

  randSentence(
    request: poem_pb.RandSentenceReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: poem_pb.RandSentenceRes) => void): grpcWeb.ClientReadableStream<poem_pb.RandSentenceRes>;

  randSentence(
    request: poem_pb.RandSentenceReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: poem_pb.RandSentenceRes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/pb.PoemService/RandSentence',
        request,
        metadata || {},
        this.methodDescriptorRandSentence,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/pb.PoemService/RandSentence',
    request,
    metadata || {},
    this.methodDescriptorRandSentence);
  }

}

