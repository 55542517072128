import { createStore } from "vuex";

interface SearchStruct {
  index: number;
  search: string;
  dynasty: number;
  genre: number;
  random: boolean;
};

interface filterItem {
  name: string;
  idx: string;
}
interface filterItf {
  search: filterItem;
  setting: filterItem;
  attach: filterItem;
  about: filterItem;
}
const filterTabs: filterItf = {
  search: {
    name: "搜索",
    idx: "search",
  },
  setting: {
    name: "设置",
    idx: "setting",
  },
  attach: {
    name: "声明",
    idx: "attach",
  },
  about: {
    name: "关于",
    idx: "about",
  }
};

const defaultSearchInfo: SearchStruct = {
  index: 0,
  search: "",
  dynasty: 0,
  genre: 0,
  random: false,
}

export default createStore({
  state: {
    navStretch: 0,
    auth: false,
    filterIdx: filterTabs.search.idx,
    filterName: filterTabs.search.name,
    jumpToPoemList: false,
    search: defaultSearchInfo,
    requestId: "",
  },
  getters: {
    navStretchWidth(state): string {
      return state.navStretch * 15 + 3 + "em";
    },
    searchInfo(state): SearchStruct {
      return state.search
    },
    requestId(state): string {
      return state.requestId
    },
    activedFilterIdx(state): string {
      return state.filterIdx;
    },
    activedFilterName(state): string {
      return state.filterName;
    },
  },
  mutations: {
    login(state) {
      state.auth = true;
    },
    logout(state) {
      state.auth = false;
    },
    changeStretch(state) {
      state.navStretch = Math.abs(state.navStretch - 1);
    },
    activeStretch(state) {
      state.navStretch = 1;
    },
    newRequestId(state) {
      state.requestId = Date.now().toString();
    },
    // setSearchIndex(state, index: number) {
    //   state.search.index = index
    // },
    // setSearchDynasty(state, dynasty: number) {
    //   state.search.dynasty = dynasty
    // },
    // setSearchGenre(state, genre: number) {
    //   state.search.genre = genre
    // },
    // setSearchStr(state, str: string) {
    //   state.search.search = str
    // },
    setRandom(state, isRandom: boolean) {
      state.search.random = isRandom;
    },
    setSearch(state, payload: SearchStruct) {
      state.search = payload
    },
    clearSearch(state) {
      state.search = defaultSearchInfo
    },
    enableFilter(state, idx: keyof filterItf) {
      const v = filterTabs[idx];
      state.filterIdx = v.idx;
      state.filterName = v.name;
    },
    jumpToPl(state) {
      state.jumpToPoemList = true;
    },
    clearPl(state) {
      state.jumpToPoemList = false;
    },
  },
  actions: {},
  modules: {},
});
