
import { defineComponent } from "vue";
export default defineComponent({
  name: "app",
  data() {
    return {
      titles: [] as string[],
      page: 0,
      total: 0,
    };
  },
  computed: {
    navWidth() {
      return this.$store.getters.navStretchWidth;
    },
    stretchIcon() {
      return this.$store.state.navStretch === 1 ? 0 : 1;
    },
  },
  watch: {
    '$store.state.jumpToPoemList'(newValue) {
      if (newValue) {
        this.$store.commit('clearPl')
        this.$router.push({name: "list"})
      }
    }
  },
  methods: {
    navWidthChange: function () {
      this.$store.commit("changeStretch");
      this.$store.commit("enableFilter", "search");
    },
    // logout: function () {
    //   this.$store.commit('logout');
    // },
    setNavTitle: function (vals: Array<string>) {
      // 遍历vals数组并处理元素
      const processedTitles = vals.map((val) => {
        if (typeof val === 'string' && val.length > 16) {
          return val.substring(0, 16) + "…"; // 保留前16位
        }

        return val; // 不是字符串或长度不超过16的元素不做处理
      });

      // 将处理后的数组赋值给titles
      this.titles = processedTitles;
    },
    setPaginator: function (page: number, totalPage: number) {
      this.page = page;
      this.total = totalPage;
    },
  },
});
