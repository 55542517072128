
import { defineComponent } from "vue";
export default defineComponent({
  name: "SearchTab",
  data() {
    return {
      msg: "Article Lists",
      searchStr: "",
      searchDynasty: 0,
      searchGenre: 0,
      searchIndex: 0,
      indexes: [
        { name: "搜索", value: 0 },
        { name: "标题", value: 1 },
        { name: "作者", value: 2 },
        { name: "全文", value: 3 },
        { name: "句首", value: 4 },
        { name: "句尾", value: 5 },
      ],
      dynasties: [
        { name: "朝代", value: 0 },
        // { name: "夏商", value: 1 },
        // { name: "两周", value: 2 },
        // { name: "秦汉", value: 3 },
        // { name: "两晋", value: 4 },
        { name: "唐", value: 10 },
        { name: "宋", value: 12 },
        // { name: "明清", value: 7 },
        // { name: "近代", value: 8 },
      ],
      genres: [
        { name: "类型", value: 0 },
        { name: "诗", value: 1 },
        { name: "词", value: 2 },
        // { name: "文", value: 2 },
        // { name: "典", value: 3 },
      ],
    };
  },
  methods: {
    clearAndRedirectToPoemList: function () {
      this.$store.commit("clearSearch");
      if (this.$route.name != 'list') {
        this.$store.commit("jumpToPl")
      }
    },
    doSearch: function () {
      this.clearAndRedirectToPoemList()
      this.$store.commit("setSearch", {
        index: this.searchIndex,
        search: this.searchStr,
        dynasty: this.searchDynasty,
        genre: this.searchGenre,
        random: false,
      });
      this.$store.commit("newRequestId");
    },
    doRandom: function () {
      this.clearAndRedirectToPoemList()
      this.$store.commit("setRandom", true);
      this.$store.commit("newRequestId")
    },
  },
});
