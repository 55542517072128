import { createApp } from "vue";
// 引入静态资源包放在引入App之前，解决打包到正式后样式错乱的问题
import "purecss/build/pure-min.css";
import "purecss/build/grids-responsive-min.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import filter from "@/components/Filter";
import scroll from "@/lib/scroll";

createApp(App).use(store).use(router).use(filter).use(scroll).mount("#app");
