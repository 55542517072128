import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import PoemList from "@/components/PoemList.vue";
import PoemDetail from "@/components/PoemDetail.vue";
import TodayFocus from "@/components/TodayFocus.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "focus",
    component: TodayFocus,
  },
  {
    path: "/pl",
    name: "list",
    component: PoemList,
  },
  {
    path: '/p/:id',
    name: 'detail',
    component: PoemDetail,
    meta: {
      keepAlive: true   //判断是否缓存
    }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
