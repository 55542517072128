
import { defineComponent, reactive } from "vue";
import { poemCli, poem } from "@/api";
export default defineComponent({
  name: 'TodayFocus',
  data() {
    let ps = new poem.RandSentence
    return {
      shortTitle: "",
      poemSentence: reactive<poem.RandSentence>(ps),
    }
  },
  activated: function () {
    this.getRandSentence();
  },
  methods: {
    activeFilter: function () {
      this.$store.commit("activeStretch");
      this.$store.commit("enableFilter", "attach");
    },
    cli: () => poemCli(),
    getDefaultSentence: function () {
      let defaultSentence = new poem.Sentence
      defaultSentence.setText("有笔头千字，胸中万卷，致君尧舜，此事何难。")
      let defaultAuthor = new poem.Author
      defaultAuthor.setName("苏轼")
      defaultAuthor.setId(1)
      let defaultDynasty = new poem.Dynasty
      defaultDynasty.setId(46)
      defaultDynasty.setTitle("北宋")
      defaultDynasty.setReferTitle("宋")
      let ps = new poem.RandSentence
      ps.setAuthor(defaultAuthor)
      ps.setDynasty(defaultDynasty)
      ps.setSentence(defaultSentence)
      ps.setUniqid("wwb9tuvd")
      ps.setTitle("沁园春")

      return ps
    },
    getRandSentence: function () {
        let req = new poem.RandSentenceReq();
        this.cli().randSentence(
            req, {}
        ).then((randSentence: poem.RandSentenceRes) => {
            let ps = randSentence.getSentence();
            if (ps) {
                this.setRandSentence(ps);
            }
        }).catch((err: Error) => {
            console.error(err);
        })
    },
    setRandSentence: function (ps: &poem.RandSentence) {
      this.poemSentence = ps;
      this.updateShortTitle(ps.getTitle())
      this.setNavTitle(ps.getTitle())

      // this.setNavTitle(ps.getTitle().substring(0, 32));
    },
    setNavTitle: function (val: string) {
      this.$emit('setNavTitle', [val]);
    },
    setShortTitle: function (val: string) {
      this.shortTitle = val
    },
    updateShortTitle: function (val: string) {
      // console.log(this.shortTitle.length)
      // let shortedStr = ps.getTitle()
      if (val.length > 16) {
        val = val.substring(0, 16) + "…"
      }

      this.setShortTitle(val)
    }
  }
});
